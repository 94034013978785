/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a",
    div: "div"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Uno de los primeros pasos hacia el e-Government, es la popularización de la administración electróni", React.createElement(_components.a, {
    href: "http://es.wikipedia.org/wiki/Archivo:Dnielectronico.jpg"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<span\n      class=\"gatsby-resp-image-wrapper\"\n      style=\"position: relative; display: block; margin-left: auto; margin-right: auto; max-width: 300px; \"\n    >\n      <span\n    class=\"gatsby-resp-image-background-image\"\n    style=\"padding-bottom: 65.82278481012659%; position: relative; bottom: 0; left: 0; background-image: url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAECBf/EABYBAQEBAAAAAAAAAAAAAAAAAAABAv/aAAwDAQACEAMQAAAB06bwoZX/xAAYEAEAAwEAAAAAAAAAAAAAAAABABEhMf/aAAgBAQABBQJW+w0rTJc//8QAFREBAQAAAAAAAAAAAAAAAAAAAhD/2gAIAQMBAT8BU//EABcRAQADAAAAAAAAAAAAAAAAAAABAhH/2gAIAQIBAT8BoyH/xAAUEAEAAAAAAAAAAAAAAAAAAAAg/9oACAEBAAY/Al//xAAbEAADAAIDAAAAAAAAAAAAAAAAAREhMWFxgf/aAAgBAQABPyG4lexcmbivuBZXSqEzP//aAAwDAQACAAMAAAAQTP8A/8QAFhEBAQEAAAAAAAAAAAAAAAAAAQAh/9oACAEDAQE/ENIW3//EABcRAQEBAQAAAAAAAAAAAAAAAAEAESH/2gAIAQIBAT8Q2Dsp2//EAB0QAQACAQUBAAAAAAAAAAAAAAEAESExQVFhkYH/2gAIAQEAAT8QAoDyQqMGLb63gbkGlBR8ZmScoG3ksoRQ4hqta8T/2Q=='); background-size: cover; display: block;\"\n  ></span>\n  <img\n        class=\"gatsby-resp-image-image\"\n        alt=\"300px Dnielectronico\"\n        title=\"300px Dnielectronico\"\n        src=\"/static/63a9d0f29ef739860205d5684ebe26a1/f93b5/300px-Dnielectronico.jpg\"\n        srcset=\"/static/63a9d0f29ef739860205d5684ebe26a1/ff44c/300px-Dnielectronico.jpg 158w,\n/static/63a9d0f29ef739860205d5684ebe26a1/f93b5/300px-Dnielectronico.jpg 300w\"\n        sizes=\"(max-width: 300px) 100vw, 300px\"\n        style=\"width:100%;height:100%;margin:0;vertical-align:middle;position:absolute;top:0;left:0;\"\n        loading=\"lazy\"\n        decoding=\"async\"\n      />\n    </span>"
    }
  })), "ca, o e-Administración. Se mediante el acceso a la administración por Internet se consigue agilizar las gestiones burocráticas y poco a poco eliminar el papel de las oficinas que todos pagamos, las de las distintas administraciones."), "\n", React.createElement(_components.p, null, "En España se ha intentado poner en práctica todo esto mediante el DNI electrónico, pero realmente no se le ha dado la publicidad que debería tener, no se ha explicado a toda la población para que lo puedan utilizar y este proyecto se puede convertir en todo un fracaso."), "\n", React.createElement(_components.p, null, "En el siguiente articulo se explican las dificultades que tiene la autora intentando hacer uso del DNIe, todo un ejemplo de periodismo de investigación tecnológico."), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "http://www.diariodenavarra.es/20110220/culturaysociedad/la-pesadilla-utilizar-dni-electronico.html?not=2011022001465965&idnot=2011022001465965&dia=20110220&seccion=culturaysociedad&seccion2=tecnologia&chnl=40&ph=8",
    title: "La pesadilla de utilizar el DNIe"
  }, "La pesadilla de utilizar el DNI electrónico")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
